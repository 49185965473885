import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import SocialLinks from 'components/Navigation/SocialLinks';
import { Link } from 'react-router-external';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import utils from 'utils';

const styles = theme => ({
  root: {},
  mainFooterNav: {
    textDecoration: 'none!important',
    '&.active, &:hover': {
      opacity: 0.8,
    },
  },
  socials: {
    '& span': {
      color: '#FFFFFF !important',
    },
  },
  [theme.breakpoints.down(BreakpointsConfig.md)]: {
    afterLink: {
      position: 'absolute',
      right: '3px',
    },
  },
  marginLink: {
    paddingTop: '15px',
    [theme.breakpoints.up(BreakpointsConfig.md)]: {
      paddingTop: '25px',
    },
  },
  [theme.breakpoints.up(BreakpointsConfig.md)]: {
    marginFooter: {
      paddingBottom: '30px',
      paddingTop: '6px',
    },
  },
});

function MainFooter({ classes, footerNavigation, socialNavigation, className }) {

  const year = (new Date()).getFullYear();

  return (
    <div className={classNames("relative bg-black z-10 footer px-20 lg:px-56", className)}>
      <div className={classNames(classes.root, 'container bg-black')}>
        <Grid
          container
          className={`md:justify-between sm:justify-center ${classes.marginFooter}`}
        >
          <Grid item>
            <List className={`flex flex-wrap justify-center ${classes.marginLink}`}>
              {footerNavigation.map((item, idx) => (
                <ListItem
                  component={Link}
                  to={item.url}
                  activeClassName="active"
                  className={classNames(
                    classes.mainFooterNav,
                    'w-auto m-0 p-0',
                  )}
                  button={false}
                  disableGutters
                >
                  <ListItemText
                    disableTypography={false}
                    classes={{
                      primary:
                        'text-20 text-white list-item-text-primary font-serif font-bold m-0 p-0 md:mr-8',
                    }}
                    className="relative"
                  >
                    <span data-track={`footer >> ${item.id}`}>
                      {item.title}
                    </span>
                    {utils.isMobile() && (idx !== footerNavigation.length - 1 && <span className={`text-teal ${classes.afterLink}`}>/</span>)}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item>
            <SocialLinks
              className={classNames(
                classes.socials,
                'flex flex-wrap justify-even md:py-12',
              )}
              links={socialNavigation}
              section="footer"
            />
          </Grid>
        </Grid>
        <Grid
          justify="space-between"
          alignItems="flex-start"
          className="border-t-1 border-grey py-12 md:flex px-12 pt-8 md:px-0 md:pt-12"
        >
          <Grid item>
            <Typography
              gutterBottom
              className="text-12 lg:text-14 mb-0 text-white sm:text-center md:text-left md:text-left margin-bottom-10 md:mb-20"
            >
              Copyright © {year} The Dining Advisor. All Rights Reserved.<br></br>We use cookies in order to provide you with better services on our website.<br></br>
              By continuing to browse the site, you agree to our <a href="https://www.uobgroup.com/uobgroup/privacy/index.page?adobe_mc=MCMID%3D54667026258580353630309776634306806828%7CMCORGID%3D116168F454E6DA2A0A4C98A6%2540AdobeOrg%7CTS%3D1649831428" target="_blank" className="text-teal">privacy notice</a> and <a href="https://www.uobgroup.com/uobgroup/privacy/cookie-policy.page" target="_blank" className="text-teal">cookies policy</a>.

            </Typography>
          </Grid>
         
          <Grid item>
            <Typography
              gutterBottom
              className="text-10 md:text-12 lg:text-14 mb-0 text-white sm:text-center md:text-center md:text-right"
            >
              You are accessing a Third-Party Website.
            </Typography>
            <Typography
              gutterBottom
              className="text-10 md:text-12 lg:text-14 mb-0 text-white sm:text-center md:text-center md:text-right"
            >
              Please read our
              {' '}
              <a
                className="text-teal"
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.uob.com.sg/important/index.html?adobe_mc=MCMID%3D80327835072457984354566248615439283978%7CMCORGID%3D116168F454E6DA2A0A4C98A6%2540AdobeOrg%7CTS%3D1538350605#links"
              >
                important information
                {' '}
              </a>
              {' '}
              on accessing Third-Party Websites before proceeding.
            </Typography>
            <Typography
              gutterBottom
              className="text-10 md:text-12 lg:text-14 mb-0 text-white sm:text-center md:text-center md:text-right"
            >
              <a href="/assets/pdf/UOB CREDIT AND DEBIT CARDS PRIVILEGES GENERAL TERMS AND CONDITIONS (2024).pdf" target="_blank" class="text-teal">
              UOB Cards Privileges Terms and Conditions apply.</a>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps({ footerNavigation, socialNavigation }) {
  return {
    footerNavigation,
    socialNavigation,
  };
}

MainFooter.propTypes = {
  classes: PropTypes.string,
  footerNavigation: PropTypes.array,
  socialNavigation: PropTypes.array,
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(MainFooter),
  ),
);
